import FinanceApi from '@/services/FinanceApi';
import Api from '@/services/Index';

const storeCreditCard = async (formData, forOnlyValidation) => {
    FinanceApi.defaults.headers.common['Only-Validation'] = forOnlyValidation;
    return FinanceApi.post('/payments/creditcard', formData);
}

const storeCash = async (formData, forOnlyValidation) => {
    FinanceApi.defaults.headers.common['Only-Validation'] = forOnlyValidation;
    return FinanceApi.post('/payments/cash', formData);
}

const storeCheck = async (formData, forOnlyValidation) => {
    FinanceApi.defaults.headers.common['Only-Validation'] = forOnlyValidation;
    return FinanceApi.post('/payments/check', formData);
}

const storePromissoryNote = async (formData, forOnlyValidation) => {
    FinanceApi.defaults.headers.common['Only-Validation'] = forOnlyValidation;
    return await FinanceApi.post('/payments/promissorynote', formData);
}

const storeMailOrder = async (formData, forOnlyValidation) => {
    FinanceApi.defaults.headers.common['Only-Validation'] = forOnlyValidation;
    return await FinanceApi.post('/payments/mailorder', formData);
}

const storeWire = async (formData) => {
    return FinanceApi.post('/payments/wire', formData);
}

const storeDiscount = async (formData) => {
    return FinanceApi.post('/payments/discount', formData);
}

const storeOffsetPayment = async (formData) => {
    return FinanceApi.post('/payments/offset', formData);
}

const endorse = async (id, formData) => {
    return FinanceApi.put('/payments/' + id + '/endorse', formData);
}

const cash = async (id, formData) => {
    return FinanceApi.put('/payments/' + id + '/cash', formData);
}

const bankCredit = async (id, formData) => {
    return FinanceApi.put('/payments/' + id + '/bankcredit', formData);
}

const getReceipt = async (number) => {
    return FinanceApi.get('/payments/receipt/' + number);
}

const getPromissory = async (paymentPlanId, id) => {
    return FinanceApi.get('/promissory/print/' + paymentPlanId + '/' + id);
}

const getEducationCredit = async (paymentPlanId, id) => {
    return FinanceApi.get('/payments/' + paymentPlanId + '/' + id + '/educationcredit');
}

const getMailOrderForm = async (paymentPlanId, id) => {
    return FinanceApi.get('/mail/order/infos/form/' + paymentPlanId + '/' + id);
}

const deletePayment = async (id) => {
    return FinanceApi.delete('/payments/' + id);
}

const updatePayment = async (id, formData) => {
    return FinanceApi.put('/payments/' + id, formData);
}

const periods = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_FINANCE;
    return Api.get('/periods?limit=-1');
}

const options = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_FINANCE;
    return Api.get('/periods/options', config);
}

const products = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_FINANCE;
    return Api.get('/products?limit=-1');
}

const createPlan = async (formData) => {
    return FinanceApi.post('/payment/plans/job', formData);
}

const cancel = async (id, explanation) => {
    return FinanceApi.put('/payments/' + id + '/cancel', {cancellation_explanation: explanation});
}

const storeBankCredit = async (formData) => {
    return FinanceApi.post('/payments/bankcredit', formData);
}

const storeEducationCredit = async (formData) => {
    return FinanceApi.post('/payments/educationcredit', formData);
}

const storeOffset = async (formData) => {
    if (formData.id > 0) {
        return FinanceApi.put('/offsets/' + formData.id, formData);
    }
    return FinanceApi.post('/offsets', formData);
}

const deleteOffset = async (id) => {
    return FinanceApi.delete('/offsets/' + id);
}

const collectionReceiptSearch = async (query) => {
    return FinanceApi.get('/payments/receipt/search', query);
}

const patchPayment = async (id, formData) => {
    return FinanceApi.patch('/payments/' + id, formData);
}

const report = async (config) => {
    return FinanceApi.get('/payments/report', config);
}

const updateDueDate = async (formData) => {
    return FinanceApi.patch('/payments/due-date', formData);
}

const move = async (id, formData) => {
    return FinanceApi.patch('/payments/'+id+'/move', formData);
}

export default {
    storeCreditCard,
    storeCash,
    storeCheck,
    storePromissoryNote,
    storeMailOrder,
    getReceipt,
    periods,
    products,
    createPlan,
    storeWire,

    getPromissory,
    getEducationCredit,
    getMailOrderForm,
    deletePayment,
    updatePayment,
    patchPayment,
    endorse,
    cancel,
    storeBankCredit,
    storeEducationCredit,
    bankCredit,
    cash,
    storeOffset,
    deleteOffset,

    storeOffsetPayment,
    collectionReceiptSearch,
    report,
    storeDiscount,
    updateDueDate,
    move,
    options
}
